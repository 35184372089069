/* Deck */
.deck {
  font-size: 20px;
  background-color: #bbc2c9;
}

.deck h3 {
  font-size: 175px;
}

.deck button,
select,
input {
  background-color: #9cad1f;
  color: #5a3e2b;
  font-family: inherit;
  border: none;
  border-radius: 10rem;
  padding: 1.2rem 3.2rem;
  font-weight: 700;
  font-size: 1.8rem;
  cursor: pointer;
  margin-bottom: 20px;
}

.deck div {
  border: 8px double hsl(0, 0%, 91%);
  border-radius: 12px;
  aspect-ratio: 1 / 1;
  display: inline-block;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2px;
  cursor: pointer;
  height: auto;
  width: auto;
}
