.durac-game {
  display: flex;
  height: 100vh;
}

.durac-game-board {
  position: relative;
  width: 1000px;
  height: 1000px;
  margin: 0 auto;
}

/* .durac-game-board .back-link {
  Add any Durac-specific positioning here if needed
} */

.durac-game-info {
  position: absolute;
  top: 10px;
  right: 10px;
  text-align: right;
}

/* New styles for game info content */
.durac-deck-count,
.durac-trump-suit {
  margin-bottom: 8px;
  font-weight: bold;
  color: #5a3e2b;
}

/* Common button styles */
.durac-button {
  font-family: inherit;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: none;
  border-radius: 10rem;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Start Game button */
.start-game-button {
  font-size: 1.6rem;
  padding: 1.2rem 2.4rem;
  background-color: #9cad1f;
  color: #5a3e2b;
  margin: 20px auto;
  display: block;
}

.start-game-button:hover {
  background-color: #b8cc24;
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

/* Restart Game button */
.restart-button {
  font-size: 1rem;
  padding: 0.6rem 1.2rem;
  background-color: #5a3e2b;
  color: #fff;
}

.restart-button:hover {
  background-color: #4a2e1b;
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.durac-hands-circle {
  position: absolute;
  width: 100%;
  height: 100%;
}

.durac-player-hand {
  position: absolute;
  width: 200px;
  height: 150px;
}

/* Remove any other styles that might be rotating or positioning the hands */

.durac-hand {
  position: relative;
  height: 120px;
}

.durac-card-wrapper {
  position: absolute;
  transition: transform 0.3s ease;
}

.durac-card-wrapper.sliver {
  width: 20px;
  overflow: hidden;
}

.durac-card-wrapper.top {
  width: auto;
  overflow: visible;
}

.durac-card-wrapper:hover {
  transform: translateY(-10px);
}

.player-name {
  text-align: center;
  margin-top: 10px;
}

.durac-center-area {
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
}

/* Update pickup button to match new style */
.pickup-button {
  font-size: 1.4rem;
  padding: 0.8rem 1.6rem;
  background-color: #9cad1f;
  color: #5a3e2b;
  margin-top: 10px;
}

.pickup-button:hover {
  background-color: #b8cc24;
  transform: translateY(-2px);
}

/* New wrapper for start game button */
.start-game-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.durac-attack-slots {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 5px;
  padding: 5px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
}
