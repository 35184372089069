:root {
  --color-background-100: #bbc2c9;
  --color-background-500: #bbc2c9;
  --color-background-900: #bbc2c9;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background-color: var(--color-background-900);
  padding: 2.4rem;
}

/* Back link */
.back-link {
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
  color: #9cad1f;
  text-decoration: none;
  font-size: 1.5rem; /* Increased from 1rem */
  padding: 0.75rem 1.5rem; /* Increased from 0.5rem 1rem */
  border: 3px solid #9cad1f; /* Increased from 2px */
  border-radius: 7.5px; /* Increased from 5px */
  transition: all 0.3s ease;
}

.back-link:hover {
  background-color: #9cad1f;
  color: #fff;
}
