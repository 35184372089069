.hand {
  position: relative;
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hand-cards {
  position: relative;
  width: 140px;
  height: 150px;
  margin-top: 10px;
}

.hand-card {
  position: absolute;
  transition: transform 0.3s ease;
}

.hand-card.stacked {
  width: 20px;
  overflow: hidden;
}

.hand-card.top {
  width: auto;
  overflow: visible;
}

.hand-card:hover {
  transform: translateY(-10px);
}

.player-info {
  text-align: center;
  font-size: 14px;
  color: #333;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 5px;
  border-radius: 5px;
  width: 100%;
}

.player-name {
  font-weight: bold;
}

.card-count {
  font-size: 12px;
}

/* Self vs Other styling */
.hand.self .hand-card:hover {
  cursor: pointer;
}

.hand.other .hand-card {
  cursor: default;
}
